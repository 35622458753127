import {
  EncompassConnectionResponse,
  EncompassErrorDetail,
  EncompassParty,
  FieldId,
  FieldValue,
  FieldValueMapping,
} from "./generated-types";
import { useEffect, useState } from "react";
import * as emHost from "@elliemae/em-ssf-guest";

export const APP_URL = getAppUrl();
// export const APP_URL = "https://lpfront.tunnelto.dev";

function getAppUrl(): string {
  const envUrl = process.env.REACT_APP_LOANPASS_URL;
  if (envUrl != null && envUrl !== "") {
    return envUrl;
  }

  const url = new URL(window.location.href);
  const host = url.host;

  // this external frame is expected to be in subdomain of the main app subdomain, as in
  // `encompass-embed.app.loanpass.io` for `app.loanpass.io`.
  // `tunnelto.dev` (which may be used for local testing) doesn't support sub-sub-domains,
  // so use a dash in that case instead of a dot.
  let removePattern = host.includes("tunnelto.dev")
    ? /^encompass-embed-/
    : /^encompass-embed\./;

  const appUrl = `${url.protocol}//${url.host.replace(removePattern, "")}`;
  return appUrl;
}

// Defined when creating/updating Encompass Partner Connect product
export const LOANPASS_PRICE_LOCK_REQUEST_TRANSACTION_TYPE =
  "LOANPASS_PRICE_LOCK_REQUEST";

export interface EncompassHost {
  application: emHost.ApplicationObject;
  transaction: emHost.TransactionObject;
}

export function useEncompassHost(): EncompassHost | null {
  const [host, setHost] = useState<EncompassHost | null>(null);

  useEffect(() => {
    (async () => {
      const application = await emHost.getObject("application");
      const transaction = await emHost.getObject("transaction");
      setHost((host) => ({ application, transaction }));
    })();
  }, []);

  return host;
}

export interface FieldResults {
  fields: FieldValueMapping[];
  errors: {
    fieldId: FieldId;
    message: string;
  }[];
}

export interface EncompassConfig {
  loanpassAuth: EncompassConnectionResponse;
  originatingParty: EncompassParty;
  originId: string;
  creditApplicationFields: { [key: FieldId]: FieldValue };
  mappingErrors: EncompassErrorDetail[];
  pipelineRecordId: string;
}

export type EncompassConfigResponse =
  | {
      status: "loaded";
      config: EncompassConfig;
    }
  | {
      status: "error";
      message: string;
    }
  | { status: "not-loaded" };

interface EncompassLoanpassCredentials {
  clientAccessId: string;
  emailAddress: string;
  password: string;
}

async function getLoanpassAuthResponse(
  transactionOrigin: emHost.TransactionOrigin,
  credentials: EncompassLoanpassCredentials | undefined = undefined,
): Promise<Response> {
  let connectUrl = `${APP_URL}/api/vendor-integrations/encompass/connect`;
  return await fetch(connectUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      partnerAccessToken: transactionOrigin.partnerAccessToken,
      originId: transactionOrigin.id,
      credentials,
    }),
  });
}

export function useEncompassConfig(
  host: EncompassHost | null,
): EncompassConfigResponse {
  const { application, transaction } = host ?? {};

  const [encompassConfig, setEncompassConfig] =
    useState<EncompassConfigResponse>({ status: "not-loaded" });

  useEffect(() => {
    (async () => {
      if (!application || !transaction) {
        return;
      }

      const currentTransaction = await transaction.get();
      const transactionOrigin = await transaction.getOrigin();

      // In some Encompass envrionments, we've seen the error that the "loan
      // is locked" when using an existing transaction. We will eventually
      // want to add a special read-only flow when viewing an existing
      // transaction, but for now we just show an error message instead.
      if (currentTransaction.id != null) {
        setEncompassConfig({
          status: "error",
          message:
            "Viewing details for an existing order is not currently supported. Please return to Encompass and place a new service order to view or update loan pricing.",
        });
        return;
      }

      try {
        let loanpassAuthResponse = await getLoanpassAuthResponse(
          transactionOrigin,
        );

        while (
          loanpassAuthResponse.status === 400 ||
          loanpassAuthResponse.status === 401
        ) {
          // If encompass credentials are missing, have the user update them.
          const credentials = (await application.performAction(
            "updateCredentials",
          )) as EncompassLoanpassCredentials;

          // Then try again.
          console.log("trying again");
          loanpassAuthResponse = await getLoanpassAuthResponse(
            transactionOrigin,
            credentials,
          );
        }

        const status = loanpassAuthResponse.status;

        if (status >= 200 && status < 300) {
          const loanpassAuth: EncompassConnectionResponse =
            await loanpassAuthResponse.json();

          setEncompassConfig({
            status: "loaded",
            config: {
              originId: transactionOrigin.id,
              loanpassAuth,
              originatingParty: loanpassAuth.originatingParty,
              creditApplicationFields:
                loanpassAuth.creditApplicationFieldValues,
              mappingErrors: loanpassAuth.mappingErrors,
              pipelineRecordId: loanpassAuth.loanpassPipelineRecordId,
            },
          });
        } else if (status === 403) {
          setEncompassConfig({
            status: "error",
            message:
              "LoanPASS credentials are configured incorrectly in Encompass.",
          });
        } else if (status === 503) {
          setEncompassConfig({
            status: "error",
            message: "Encompass integration has not been enabled in LoanPASS.",
          });
        }
      } catch (e) {
        console.error("Error calling `connect` endpoint:", e);
        setEncompassConfig({
          status: "error",
          message: "Failed to load the Encompass configuration.",
        });
      }
    })();
  }, [application, transaction]);

  return encompassConfig;
}
