import { useCallback } from "react";
import "./App.css";
import { APP_URL, useEncompassConfig, useEncompassHost } from "./hooks";
import { OverlayScreen, LoanpassEmbed } from "./LoanpassEmbed";

function App() {
  const encompassHost = useEncompassHost();
  const encompassConfig = useEncompassConfig(encompassHost);

  const onAppClose: React.MouseEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      encompassHost?.transaction.close();
    },
    [encompassHost],
  );

  const onAppCancel: React.MouseEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      encompassHost?.transaction.cancel();
    },
    [encompassHost],
  );

  function contents(): JSX.Element {
    if (encompassHost === null || encompassConfig.status === "not-loaded") {
      return (
        <OverlayScreen
          state={{ state: "pending" }}
          encompassHost={encompassHost}
        />
      );
    }

    if (encompassConfig.status === "error") {
      return (
        <OverlayScreen
          state={{
            state: "error",
            message: encompassConfig.message,
            details: [],
            dismissable: false,
          }}
          encompassHost={encompassHost}
        />
      );
    }

    return (
      <LoanpassEmbed
        appUrl={APP_URL}
        encompassHost={encompassHost}
        encompassConfig={encompassConfig.config}
      />
    );
  }

  return (
    <div className="app-chrome">
      <nav>
        <button
          className="button"
          disabled={
            encompassHost === null ||
            encompassConfig.status === "not-loaded" ||
            encompassConfig.status === "error"
          }
          onClick={onAppClose}
        >
          Close
        </button>
        <button
          className="button"
          disabled={
            encompassHost === null ||
            encompassHost === null ||
            encompassConfig.status === "not-loaded"
          }
          onClick={onAppCancel}
        >
          Cancel
        </button>
      </nav>
      <div className="app-contents">{contents()}</div>
    </div>
  );
}

export default App;
